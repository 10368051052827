<div *ngIf="simpleCounter"
     class="wrapper">
  <ng-container *ngIf="simpleCounter.type===SimpleCounterType.StopWatch">
    <button (click)="toggleStopwatch()"
            (contextmenu)="edit($event)"
            (longPress)="edit()"
            [color]="simpleCounter.isOn? 'accent': ''"
            class="main-btn stopwatch"
            mat-mini-fab>
      <mat-icon *ngIf="!simpleCounter.isOn">{{simpleCounter.icon}}</mat-icon>
      <mat-icon *ngIf="simpleCounter.isOn">{{simpleCounter.iconOn}}</mat-icon>
    </button>
    <div *ngIf="simpleCounter.countOnDay[todayStr]"
         class="label">{{simpleCounter.countOnDay[todayStr]|msToMinuteClockString}}</div>
  </ng-container>

  <ng-container *ngIf="simpleCounter.type===SimpleCounterType.ClickCounter">
    <button (click)="toggleCounter()"
            (contextmenu)="edit($event)"
            (longPress)="edit()"
            class="main-btn"
            color=""
            mat-mini-fab>
      <mat-icon>{{simpleCounter.icon}}</mat-icon>
    </button>
    <div *ngIf="simpleCounter.countOnDay[todayStr]"
         class="label">{{simpleCounter.countOnDay[todayStr]}}</div>
  </ng-container>

  <!--  <div class="controls">-->
  <!--    <button (click)="edit()"-->
  <!--            class="btn"-->
  <!--            color=""-->
  <!--            [title]="T.G.EDIT|translate"-->
  <!--            mat-mini-fab>-->
  <!--      <mat-icon>edit</mat-icon>-->
  <!--    </button>-->
  <!--    &lt;!&ndash;    <button (click)="reset()"&ndash;&gt;-->
  <!--    &lt;!&ndash;            class="btn"&ndash;&gt;-->
  <!--    &lt;!&ndash;            color=""&ndash;&gt;-->
  <!--    &lt;!&ndash;            [title]="T.G.RESET|translate"&ndash;&gt;-->
  <!--    &lt;!&ndash;            mat-mini-fab>&ndash;&gt;-->
  <!--    &lt;!&ndash;      <mat-icon>undo</mat-icon>&ndash;&gt;-->
  <!--    &lt;!&ndash;    </button>&ndash;&gt;-->
  <!--  </div>-->
</div>
