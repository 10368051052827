<mat-dialog-content>
  <owl-wrapper (triggerSubmit)="save()"
               [(dateTime)]="dateTime"></owl-wrapper>

  <div class="form-wrapper">
    <div class="additional-fields-wrapper">
      <mat-form-field>
        <input
          [(ngModel)]="title"
          [placeholder]="T.F.NOTE.D_ADD_REMINDER.L_TITLE|translate"
          matInput
          name="title"
          required
          type="text">
        <mat-error>{{T.F.NOTE.D_ADD_REMINDER.E_ENTER_TITLE|translate}}</mat-error>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="wrap-buttons">
    <button (click)="close()"
            [title]="T.G.CANCEL|translate"
            class="btn btn-primary submit-button"
            color="primary"
            mat-button
            type="button">
      <mat-icon>close</mat-icon>
    </button>

    <button (click)="remove()"
            *ngIf="isEdit"
            [title]="T.F.NOTE.REMOVE_REMINDER|translate"
            class="btn btn-primary submit-button"
            color="primary"
            mat-stroked-button
            type="button">
      <mat-icon>alarm_off</mat-icon>
    </button>

    <button (click)="save()"
            [title]="(reminder
            ?T.F.NOTE.UPDATE_REMINDER
            :T.F.NOTE.ADD_REMINDER)|translate"
            class="btn btn-primary submit-button"
            color="primary"
            mat-stroked-button
            type="submit">
      <mat-icon *ngIf="!note.reminderId">alarm_add</mat-icon>
      <mat-icon *ngIf="note.reminderId">alarm</mat-icon>
    </button>
  </div>
</mat-dialog-actions>
