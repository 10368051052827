<ng-container *ngIf="task?.issueType===JIRA_TYPE">
  <jira-issue-content [issue]="issueData"
                      [task]="task"></jira-issue-content>
</ng-container>

<ng-container *ngIf="task?.issueType===GITHUB_TYPE">
  <github-issue-content [issue]="issueData"
                        [task]="task"></github-issue-content>
</ng-container>

<ng-container *ngIf="task?.issueType===GITLAB_TYPE">
  <gitlab-issue-content [issue]="issueData"
                        [task]="task"></gitlab-issue-content>
</ng-container>
