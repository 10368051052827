<div class="task-list-wrapper">
  <section class="add-task-bar-wrapper">
    <add-task-bar [isAddToBottom]="true"
                  [isDisableAutoFocus]="true"></add-task-bar>
  </section>

  <task-list [tasks]="workContextService.undoneTasks$|async"
             listId="PARENT"
             listModelId="UNDONE"></task-list>
</div>
