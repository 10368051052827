<ng-template #spinner>
  <full-page-spinner></full-page-spinner>
</ng-template>

<div *ngIf="(reminderService.isRemindersLoaded$|async); else spinner"
     class="component-wrapper page-wrapper"
     style="margin-top: 24px;">
  <p *ngIf="!(scheduledTaskService.allScheduledTasks$|async)?.length"
     class="no-scheduled-tasks">
    {{T.SCHEDULE.NO_SCHEDULED|translate}}
  </p>

  <div [@standardList]="(scheduledTaskService.allScheduledTasks$|async)?.length"
       class="tasks">
    <mat-card
      *ngFor="let task of (scheduledTaskService.allScheduledTasks$|async); trackBy: trackByFn;"
      class="scheduled-task">
      <mat-card-content>
        <div class="content">

          <div class="task-info">
            <div class="title">
              <div (editFinished)="updateTaskTitleIfChanged($event.isChanged, $event.newVal, task)"
                   class="task-title"
                   contentEditableOnClick
                   contenteditable="true">{{task.title}}</div>
              <tag-list [isShowProjectTagAlways]="true"
                        [task]="task"></tag-list>
            </div>

            <div (click)="editReminder(task)"
                 class="due-date"
                 mat-ripple>
              <div class="date-and-time-left">
                <div class="date hide-xs">{{task.reminderData.remindAt|date:'EE, d MMM, h:mm'}}</div>
                <div class="date show-xs-only">{{task.reminderData.remindAt|date:'d MMM, h:mm'}}</div>

                <div class="time-left">
                  ({{task.reminderData.remindAt|humanizeTimestamp}})
                </div>
              </div>
              <mat-icon>alarm</mat-icon>
            </div>

            <button (click)="startTask(task)"
                    [matTooltip]="T.SCHEDULE.START_TASK|translate"
                    class="action-btn"
                    mat-icon-button>
              <mat-icon svgIcon="play"></mat-icon>
            </button>

            <button *ngIf="task.parentId && task.parentData"
                    (click)="toggleToday(task.parentData)"
                    [matTooltip]="(task.parentData.tagIds.includes(TODAY_TAG.id)
                    ?T.F.TASK.CMP.REMOVE_FROM_MY_DAY
                    :T.F.TASK.CMP.ADD_TO_MY_DAY)|translate"
                    class="action-btn"
                    mat-icon-button>
              <mat-icon *ngIf="task.parentData.tagIds.includes(TODAY_TAG.id)"
                        svgIcon="remove_today"></mat-icon>
              <mat-icon *ngIf="!task.parentData.tagIds.includes(TODAY_TAG.id)">wb_sunny</mat-icon>
            </button>

            <button *ngIf="!task.parentId"
                    (click)="toggleToday(task)"
                    [matTooltip]="(task.tagIds.includes(TODAY_TAG.id)
                    ?T.F.TASK.CMP.REMOVE_FROM_MY_DAY
                    :T.F.TASK.CMP.ADD_TO_MY_DAY)|translate"
                    class="action-btn"
                    mat-icon-button>
              <mat-icon *ngIf="task.tagIds.includes(TODAY_TAG.id)"
                        svgIcon="remove_today"></mat-icon>
              <mat-icon *ngIf="!task.tagIds.includes(TODAY_TAG.id)">wb_sunny</mat-icon>
            </button>

          </div>
        </div>

      </mat-card-content>
    </mat-card>
  </div>

</div>
