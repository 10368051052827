<mat-dialog-content>
  <div [innerHtml]="data.message|translate:data.translateParams"
       class="content"></div>
  <mat-form-field>
    <input type="text"
           [placeholder]="data.placeholder"
           matInput
           [(ngModel)]="txtVal">
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="wrap-buttons">
    <button (click)="close(false)"
            class="btn btn-primary submit-button"
            mat-button
            type="button">
      <mat-icon>close</mat-icon>
      {{(data.cancelTxt || T.G.CANCEL)|translate}}
    </button>

    <button (click)="close(true)"
            class="btn btn-primary submit-button"
            color="primary"
            mat-stroked-button>
      <mat-icon>check</mat-icon>
      {{(data.saveTxt || T.G.SAVE)|translate}}
    </button>
  </div>
</mat-dialog-actions>
