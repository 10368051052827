<input [errorStateMatcher]="errorStateMatcher"
       [formControl]="formControl"
       [formlyAttributes]="field"
       [id]="id"
       [isAllowSeconds]="field.templateOptions?.isAllowSeconds"
       [placeholder]="to.placeholder"
       [type]="'text'"
       inputDuration
       matInput>

