<h1 mat-dialog-title>
  <mat-icon class="dialog-header-icon">alarm</mat-icon>
  <span>{{T.F.NOTE.D_VIEW_REMINDER.TITLE|translate}}</span>
</h1>

<mat-dialog-content>
  <div *ngIf="isForCurrentContext"
       class="note">
    {{(note$|async)?.content }}
  </div>

  <ng-container *ngIf="!isForCurrentContext">
    <p>From Project</p>
    <h3>{{(targetContext$|async)?.title}}</h3>
    <div class="note">
      {{reminder.title }}
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="wrap-buttons">
    <button (dblclick)="snooze(10)"
            [matMenuTriggerFor]="snoozeMenu"
            color="primary"
            mat-button
            type="button">
      <mat-icon>snooze</mat-icon>
      {{T.F.NOTE.D_VIEW_REMINDER.SNOOZE|translate:{time: '10m'} }}
    </button>

    <button (click)="dismiss()"
            color="primary"
            mat-stroked-button
            type="button">
      <mat-icon>alarm_off</mat-icon>
      {{T.G.DISMISS|translate}}
    </button>
  </div>
</mat-dialog-actions>


<mat-menu #snoozeMenu="matMenu">
  <button (click)="snooze(10)"
          mat-menu-item>
    <mat-icon>snooze</mat-icon>
    {{T.G.MINUTES|translate:{m: 10} }}
  </button>
  <button (click)="snooze(30)"
          mat-menu-item>
    <mat-icon>snooze</mat-icon>
    {{T.G.MINUTES|translate:{m: 30} }}
  </button>
  <button (click)="snooze(60)"
          mat-menu-item>
    <mat-icon>snooze</mat-icon>
    {{T.G.MINUTES|translate:{m: 60} }}
  </button>
  <!--  <button mat-menu-item-->
  <!--          (click)="editReminder()">-->
  <!--    <mat-icon>edit</mat-icon>{{T.G.EDIT|translate}}-->
  <!--  </button>-->
</mat-menu>
