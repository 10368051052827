<collapsible [isExpanded]="isExpanded"
             [isIconBefore]="true"
             [title]="(section?.title|translate)">
  <!--[icon]="section?.icon"-->

  <help-section *ngIf="section?.help||section?.helpArr">
    <div *ngIf="section?.help"
         [innerHtml]="section?.help|translate"></div>
    <ng-container *ngIf="section?.helpArr">
      <ng-container *ngFor="let helpSection of section?.helpArr; trackBy: trackByIndex">
        <div *ngIf="helpSection.h"
             class="mat-caption">{{helpSection.h|translate}}</div>
        <p *ngIf="helpSection.p"
           [innerHTML]="helpSection.p|translate"></p>
        <p *ngIf="helpSection.p2"
           [innerHTML]="helpSection.p2|translate"></p>
        <p *ngIf="helpSection.p3"
           [innerHTML]="helpSection.p3|translate"></p>
        <p *ngIf="helpSection.p4"
           [innerHTML]="helpSection.p4|translate"></p>
      </ng-container>

    </ng-container>
  </help-section>
  <div class="form-wrapper {{section?.key}}">
    <config-form
      (save)="onSave($event)"
      *ngIf="section?.items && !section?.customSection"
      [cfg]="cfg"
      [formCfg]="section?.items"
      [sectionKey]="section?.key"></config-form>

    <ng-template #customForm
                 class="custom-form"></ng-template>
  </div>
</collapsible>
