<h1 mat-dialog-title>{{isEdit
  ? (T.F.TAG.D_EDIT.EDIT|translate:{title: title})
  : (T.F.TAG.D_EDIT.ADD|translate:{title: title})}}</h1>

<mat-dialog-content>
  <h3 class="mat-h3"></h3>

  <div class="form-wrapper">
    <chip-list-input (addItem)="addTag($event)"
                     (addNewItem)="addNewTag($event)"
                     (removeItem)="removeTag($event)"
                     [isAutoFocus]="true"
                     [label]="T.F.TAG.D_EDIT.LABEL|translate"
                     [model]="tagIds"
                     [suggestions]="(tagSuggestions$|async)"></chip-list-input>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button (click)="close()"
          color="primary"
          mat-stroked-button
          type="submit">
    <mat-icon>close</mat-icon>
    {{T.G.CLOSE|translate}}
  </button>
</mat-dialog-actions>
