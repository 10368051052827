<form (submit)="track()">
  <div class="dialog-content"
       mat-dialog-content>
    <p>{{T.F.TIME_TRACKING.D_TRACKING_REMINDER.UNTRACKED_TIME|translate}}</p>
    <div class="time">{{data.remindCounter$|async|msToString:true}}</div>

    <select-task (taskChange)="onTaskChange($event)"
                 [initialTask]="selectedTask"></select-task>

    <div class="track-to-label">
      <span *ngIf="!isCreate">{{T.F.TIME_TRACKING.D_TRACKING_REMINDER.TRACK_TO|translate}}</span>
      <span *ngIf="isCreate"
            [innerHTML]="T.F.TIME_TRACKING.D_TRACKING_REMINDER.CREATE_AND_TRACK|translate"></span>
    </div>
  </div>


  <div align="center"
       mat-dialog-actions>
    <button (click)="cancel()"
            color=""
            mat-button
            type="button">
      <mat-icon>close</mat-icon>
      {{T.G.CANCEL|translate}}
    </button>

    <button [disabled]="!(selectedTask||newTaskTitle)"
            color="primary"
            mat-stroked-button
            type="submit">
      <mat-icon *ngIf="!isCreate">track_changes</mat-icon>
      <mat-icon *ngIf="isCreate">add</mat-icon>
      {{T.F.TIME_TRACKING.D_TRACKING_REMINDER.TASK|translate}}
    </button>
  </div>
</form>
