<div [class.isHideShadow]="pos>10"
     class="handle bgc-600">
  <button #buttonEl
          (dblclick)="toggle()"
          (mousedown)="onMouseDown()"
          (touchstart)="onTouchStart()"
          [class.isAnimate]="isAnimateBtn"
          color="primary"
          mat-fab>
    <span *ngIf="counter"
          class="counter">{{counter}}</span>
    <mat-icon>library_books</mat-icon>
  </button>
</div>
