<section class="main">
  <div *ngFor="let context of (workContextService.mainWorkContexts$|async); trackBy: trackById; "
       [class.isActiveContext]="context.id===activeWorkContextId"
       [class.hasTasks]="context.taskIds.length"
       class="tag">
    <div [style.background]="context.theme.primary"
         class="tag-color"></div>
    <button [routerLink]="['tag', context.id, 'tasks']"
            mat-menu-item
            routerLinkActive="isActiveRoute">
      <span class="badge">{{context.taskIds.length}}</span>
      <mat-icon>{{context.icon}}</mat-icon>
      {{context.title}}
    </button>

    <button [mat-menu-trigger-for]="tagMenu"
            class="tag-settings-btn"
            mat-icon-button
            routerLinkActive="isActiveRoute">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #tagMenu="matMenu">
      <work-context-menu [contextId]="context.id"
                         [contextType]="context.type"></work-context-menu>
    </mat-menu>
  </div>

  <button class="route-link"
          mat-menu-item
          routerLink="schedule"
          routerLinkActive="isActiveRoute">
    <mat-icon>access_alarms</mat-icon>
    {{T.MH.SCHEDULED|translate}}
  </button>
</section>

<section *ngIf="projectList$|async as projectList"
         class="projects">
  <button (click)="toggleExpandProjects()"
          [class.isExpanded]="isProjectsExpanded"
          class="expand-btn"
          mat-menu-item>
    <span class="title">{{T.MH.PROJECTS|translate}}</span>
    <mat-icon>expand_more</mat-icon>
  </button>

  <div [@standardList]="projectList?.length"
       [dragulaModel]="projectList"
       [dragula]="PROJECTS_SIDE_NAV">
    <div *ngFor="let project of projectList; trackBy: trackById;"
         [class.isActiveContext]="project.id===activeWorkContextId"
         [class.hasTasks]="project.taskIds.length"
         class="project">
      <div [ngStyle]="getThemeColor(project.theme?.primary || project.themeColor)"
           class="project-color"></div>
      <button [routerLink]="['project', project.id, 'tasks']"
              mat-menu-item>
        <span class="badge">{{project.taskIds.length}}</span>
        <mat-icon class="drag-handle"
                  drag-handle>list
        </mat-icon>
        {{project.title}}
      </button>

      <button [matMenuTriggerFor]="projectMenu"
              class="project-settings-btn"
              mat-icon-button
              routerLinkActive="isActiveRoute">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #projectMenu="matMenu">
        <work-context-menu [contextId]="project.id"
                           [contextType]="WorkContextType.PROJECT"></work-context-menu>
      </mat-menu>
    </div>
  </div>

  <button *ngIf="isProjectsExpanded"
          mat-menu-item
          routerLink="project-overview"
          routerLinkActive="isActiveRoute">
    <mat-icon>format_list_numbered_rtl</mat-icon>
    {{T.MH.MANAGE_PROJECTS|translate}}
  </button>

  <button (click)="addProject()"
          *ngIf="isProjectsExpanded"
          mat-menu-item>
    <mat-icon>add</mat-icon>
    {{T.MH.CREATE_PROJECT|translate}}
  </button>
</section>


<section *ngIf="(tagList$|async) as tagList"
         class="tags">
  <button (click)="toggleExpandTags()"
          [class.isExpanded]="isTagsExpanded"
          class="expand-btn"
          mat-menu-item>
    <span class="title">{{T.MH.TAGS|translate}}</span>
    <mat-icon>expand_more</mat-icon>
  </button>

  <div [@standardList]="tagList?.length"
       [dragulaModel]="tagList"
       [dragula]="TAG_SIDE_NAV">
    <div *ngFor="let tag of tagList; trackBy: trackById;"
         [class.isActiveContext]="tag.id===activeWorkContextId"
         [class.hasTasks]="tag.taskIds.length"
         class="tag">
      <div [style.background]="tag?.theme.primary"
           class="tag-color"></div>
      <button [routerLink]="['tag', tag.id, 'tasks']"
              mat-menu-item>
        <span class="badge">{{tag.taskIds.length}}</span>
        <mat-icon class="drag-handle"
                  drag-handle>style
        </mat-icon>
        {{tag.title}}
      </button>

      <button [mat-menu-trigger-for]="tagMenu"
              class="tag-settings-btn"
              mat-icon-button
              routerLinkActive="isActiveRoute">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #tagMenu="matMenu">
        <work-context-menu [contextId]="tag.id"
                           [contextType]="WorkContextType.TAG"></work-context-menu>
      </mat-menu>
    </div>
  </div>
  <!--  <button (click)="addTag()"-->
  <!--          *ngIf="isTagsExpanded"-->
  <!--          mat-menu-item>-->
  <!--    <mat-icon>add</mat-icon>-->
  <!--    {{T.MH.CREATE_TAG|translate}}-->
  <!--  </button>-->
</section>


<section class="app">
  <button class="route-link"
          mat-menu-item
          routerLink="config"
          routerLinkActive="isActiveRoute">
    <mat-icon>settings_applications</mat-icon>
    <span class="text">{{T.MH.SETTINGS|translate}}</span>
  </button>

  <button (click)="onScrollToNotesClick()"
          *ngIf="workContextService.isActiveWorkContextProject$|async"
          [@expandFade]
          class="route-link scroll-down-btn"
          mat-menu-item>
    <mat-icon>notes</mat-icon>
    <span class="text">{{T.MH.NOTES|translate}}</span>
    <mat-icon>arrow_downwards</mat-icon>
  </button>
</section>


<!-- NOTE: needs to be here for mat menu styles always to be loaded -->
<mat-menu></mat-menu>
