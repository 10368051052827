<h1 mat-dialog-title>
  <mat-icon class="dialog-header-icon"
            svgIcon="github"></mat-icon>
  <span>{{T.F.GITHUB.DIALOG_INITIAL.TITLE|translate}}</span>
</h1>

<form (submit)="saveGithubCfg(githubCfg)"
      [formGroup]="formGroup">
  <mat-dialog-content>
    <formly-form (modelChange)="githubCfg=$event"
                 [fields]="formConfig"
                 [form]="formGroup"
                 [model]="githubCfg">
    </formly-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button (click)="close()"
            color="primary"
            mat-button
            type="button">
      {{T.G.CANCEL|translate}}
    </button>

    <button [disabled]="!formGroup.valid"
            color="primary"
            mat-stroked-button
            type="submit">
      <mat-icon>save</mat-icon>
      {{T.G.SAVE|translate}}
    </button>
  </mat-dialog-actions>
</form>
