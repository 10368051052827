<div class="page-wrapper">
  <div class="component-wrapper">
    <h1 class="mat-h1"
        style="display: flex; align-items: center;">
      <mat-icon style="margin-right: 8px;">style</mat-icon>
      <span>{{T.PS.TAG_SETTINGS|translate}}</span>
    </h1>
    <section class="config-section">
      <config-section (save)="saveBasicSettings($event)"
                      [cfg]="activeWorkContext"
                      [section]="basicFormCfg"></config-section>
    </section>


    <section class="config-section">
      <config-section (save)="saveTagThemCfg($event)"
                      [cfg]="currentWorkContextTheme"
                      [section]="tagThemeSettingsFormCfg"></config-section>
    </section>
  </div>
</div>
